<template>
  <div id="contact" class="container-xxl header">
    <h6>{{ $t("contact.teaser") }}</h6>
    <h2 class="text-primary">{{ $t("contact.title") }}</h2>
    <div class="row g-10">
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 bg-light">
        <div class="inner" style="padding: 20px !important">
          <div
            id="confirmation"
            v-if="sent == true"
            v-html="$t('contact.confirmation')"
          ></div>
          <form @submit="onSubmit" v-if="sent == false">
            <div class="mb-3">
              <label for="fullname" class="form-label">Vorname Name</label>
              <input
                type="text"
                class="form-control form-control-sm"
                id="fullname"
                name="fullname"
                v-model="form.fullname"
                required
              />
            </div>
            <div class="mb-3">
              <label for="email" class="form-label">Email address</label>
              <input
                type="email"
                class="form-control form-control-sm"
                id="email"
                name="email"
                v-model="form.email"
                required
              />
            </div>
            <div class="mb-3">
              <label for="phone" class="form-label">Telefon</label>
              <input
                type="text"
                class="form-control form-control-sm"
                id="phone"
                name="phone"
                v-model="form.phone"
                required
              />
            </div>
            <div class="mb-3">
              <label for="message" class="form-label">Nachricht</label>
              <textarea
                class="form-control form-control-sm"
                id="message"
                rows="3"
                name="message"
                v-model="form.message"
                required
              ></textarea>
            </div>
            <button type="submit" class="btn btn-primary">Senden</button>
          </form>
        </div>
      </div>
      <div class="col-xl-6 col-lg-6 col-md-12 col-sm-12 col-xs-12 bg-light">
        <div style="margin: 0 !important; padding: 0 !important; border: 0 !important">
          <iframe
            class="map"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d5408.769096868112!2d8.511034967306323!3d47.326360736022934!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x479008327b53e8dd%3A0xaf17a2ca15fdb16e!2sZahnarztpraxis%20Dr.%20Forouzan!5e0!3m2!1sde!2sch!4v1623171647421!5m2!1sde!2sch"
            loading="lazy"
          ></iframe>
        </div>
      </div>
    </div>
  </div>

  <Footer />
</template>

<script>
import Footer from "@/components/Footer.vue";
import axios from "axios";
import qs from "qs";

export default {
  name: "Contact",
  data() {
    return {
      sent: false,
      form: {
        ri: "1908c358-28e5-430c-aeea-8d3f759260e1",
        fullname: "",
        email: "",
        phone: "",
        message: "",
      },
    };
  },
  components: {
    Footer,
  },
  methods: {
    onSubmit(e) {
      e.preventDefault();
      console.log(qs.stringify(this.form));

      axios
        .post("https://www.mc2-advertising.ch/mail.php", qs.stringify(this.form))
        .then((res) => {
          console.log(res.data);
          this.sent = res.data === "Success";
        });
    },
  },
};
</script>

<style scoped>
.container-xxl {
  margin-bottom: 50px;
}

.header {
  padding-top: 50px;
}
</style>
